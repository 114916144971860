<template>
    <iq-card :style="daybanner" class="bday-banner">          
        <template v-slot:headerTitle>
            <h4 class="card-title">Upcoming Birthday</h4>
        </template>
        <template v-slot:body>
            <ul class="suggestions-lists m-0 p-0">
            <li v-for="(item,index) in suggestions" :key="index" class="d-flex mb-4 align-items-center">
                <div class="user-img img-fluid">
                <b-img v-if='item.image' :src="item.image" alt="story-img" rounded="circle" class="avatar-40" />
                <b-img v-else src="../../../assets/images/user-icon.svg"  alt="story-img" rounded="circle" class="avatar-40"/>
                </div>
                <div class="media-support-info ml-3">
                <h6>{{ item.name }}</h6>
                <p class="mb-0">{{ item.mutual_friend.slice(5) }}</p>
                </div>
            </li>
            </ul>
        </template>
    </iq-card>
</template>

<script>
import IqCard from '../../../components/socialvue/cards/iq-card'
import axios from '../../../components/axios'

export default {
    name: 'RecentBirthdate',
    data () {
        return {
             daybanner: {
                backgroundImage: `url(${require('@/assets/images/dashboard-graphic2.png')})`
            },
            bdaybanner: [
                {
                    img: require('../../../assets/images/dashboard-graphic2.png'),
                    // img:require('../../../'),
                    heading: 'birthday banner'
                },
                {
                    img: require('../../../assets/images/dashboard-graphic1.png'),
                    heading: 'wfd'
                }
            ],  
            suggestions: [],
        }
    },
    async mounted () {
        var parent = this
        axios.defaults.headers["Authorization"] = 
        "Bearer " + localStorage.getItem("token");

        const config = {
        method: "get",
        url: "upcomingbirthday",
        };

        axios(config).then(res => {
            parent.suggestions = res.data.birthday
        }).catch(err => {
            console.log(err.response);
        })
    }

}
</script>
