<template>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">Updates</h4>
        </template>
        <template v-slot:body>
            <ul class="media-story m-0 p-0">
            <li class="d-flex mb-4 align-items-center " v-for="(event,index) in event" :key="index" style="cursor:pointer" > 
                <img v-if='event.img' :src="event.img" alt="story-img" class="rounded-circle img-fluid">
                <img v-else src="../../../../assets/images/page-img/s4.jpg" alt="story-img" class="rounded-circle img-fluid">
            <router-link :to="/event/ + event.id">
                <div class="stories-data ml-3" >
                    <h5>{{event.caption}}</h5>
                    <p class="mb-0">{{event.time}}</p>
                </div>
                </router-link>
                <!-- <b-modal :id="'modalE' + event.id" centered title="Event" hide-footer>
                    <div class="d-flex align-items-center">
                       <h5>{{event.caption}} ( {{event.time}} )</h5>

                    </div>
                    <hr/>
                    <h5>Event Description</h5>
                    <p>{{event.description}}</p>
                   
                </b-modal> -->
            </li>
            </ul>
        </template>
    </iq-card>
</template>


<script>
import axios from '../../../../components/axios'

export default {
    name: 'Events',
    data () {
        return {
            event: []
        }
    },
    async mounted () {
        var parent = this
        axios.defaults.headers["Authorization"] = 
        "Bearer " + localStorage.getItem("token");

        const config = {
            method: "get",
            url: "latestnews",
        };

        axios(config).then(res => {
            res.data.news.forEach(element => {
                if(element.images){
                    element.img = element.images[0]
                }
                
            })
            parent.event = res.data.news
        }).catch(err => {
            console.log(err);
        })
    },
    methods:{
        modalId(i) {
            return 'modalE' + i;
        }
    }

}
</script>