<template>
  <div>
    <iq-card
      id="post-modal-data"
      body-class="iq-card iq-card-block iq-card-stretch iq-card-height"
    >
      <template v-slot:headerTitle>
        <h4 class="card-title">Create Post</h4>
      </template>
      <div class="iq-card-body" v-b-modal.modal1>
        <div class="d-flex align-items-center">
          <div class="user-img">
            <img v-if='avatar'
              class="avatar-60 rounded-circle"
              :src="avatar"
            />
            <img v-else  src="../../../../assets/images/user-icon.svg" class="avatar-60 rounded-circle" alt="user">
          </div>
          <form class="post-text ml-3 w-100">
            <input
              type="text"
              placeholder="Write something about post..."
              class="rounded form-control"
              v-model="post.caption"
              style="border:none;"
            />
          </form>
        </div>
        <hr />
        
        <ul
          class="post-opt-block d-flex align-items-center list-inline m-0 p-0"
        >
          <li class="iq-bg-primary rounded p-2 pointer mr-3">
            <a href="#"></a
            ><img
              src="../../../../assets/images/small/07.png"
              alt="icon"
              class="img-fluid"
            />
             What's in your mind
          </li>
          <!-- <li class="iq-bg-primary rounded p-2 pointer mr-3">
            <a href="#"></a
            ><img
              src="../../../../assets/images/small/09.png"
              alt="icon"
              class="img-fluid"
            />
            Feeling/Activity
          </li> -->
          <!-- <li class="iq-bg-primary rounded p-2 pointer mr-3">
            <a href="#"></a
            ><img
              src="../../../../assets/images/small/upload.png"
              alt="icon"
              class="img-fluid"
            />
            File Upload
          </li> -->
          <!-- <li class="iq-bg-primary rounded p-2 pointer">
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <div class="dropdown">
                <span
                  class="dropdown-toggle"
                  id="post-option"
                  data-toggle="dropdown"
                >
                  <i class="ri-more-fill"></i>
                </span>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="post-option"
                  style=""
                >
                  <a class="dropdown-item" href="#">Upload Photos</a>
                  <a class="dropdown-item" href="#">Create Album</a>
                  <a class="dropdown-item" href="#">Feeling/Activity</a>
                  <a class="dropdown-item" href="#">Upload File</a>
                  <a class="dropdown-item" href="#">Upload Video</a>
                  <a class="dropdown-item" href="#">Upload Music</a>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <b-modal id="modal1" centered title="Create Post" hide-footer>
        <div class="d-flex align-items-center">
          <div class="user-img">
            <img v-if='avatar'
              :src="avatar"
              alt="userimg"
              class="avatar-60 rounded-circle img-fluid"
            />
            <img v-else  src="../../../../assets/images/user-icon.svg" class="avatar-60 rounded-circle img-fluid" alt="user">
          </div>
          <form class="post-text ml-3 w-100">
            <input
              type="text"
              placeholder="Write something about post..."
              class="rounded form-control"
              v-model="post.caption"
              style="border:none;"
            />
          </form>
        </div>
        <hr />
        <!-- <input type="checkbox" v-model="music" name="music" id=""> Post Music -->
        <!-- <div class="form-group form-check">
            <input type="checkbox" v-model="music" id="music" class="form-check-input">
            <label class="form-check-label" for="music">Post Music</label>
        </div> -->
        <div>
          <div v-if='music'>
        <input
          type="text"
          placeholder="Paste your music link here..."
          class="rounded form-control"
          v-model="post.musicLink"
          style="border:none;"
        />
        </div>
        <div>
        <div class=" p-2 my-2 prev-section">
            
          
          <template v-if="preview_list.length">
            <p>Preview:</p>
            <div class="row">
            <div v-for="(item, index) in preview_list" :key="index" class="col-md-4">
              <div class="">
              <img :src="item" class="img-fluid" width="200" />
              <p class="mb-0">file name: {{ post.media[index].name }}</p>
              </div>
             
            </div>
            </div>
          </template>
        </div>

        <ul class="d-flex flex-wrap align-items-center list-inline m-0 p-0">
          <li class="col-md-6 mb-3">
            <div class="iq-bg-primary rounded p-2 pointer mr-3 d-flex">
              <select name="Album" id="album" v-model="post.albumName" :disabled='post.tip ?true : false'>
                <option value="">Select Album</option>
                <option value="Teams">Teams</option>
                <option value="Pets">Pets</option>
                <option value="Babies">Babies</option>
                <option value="Cooking">Cooking Diaries</option>
                <option value="Events">Events</option>
                <option value="Health">Videos</option>
              </select>
              <span class="btn btn-primary btn-file px-3 ml-2"
                ><i class="ri-upload-2-line"></i>
                <input
                  type="file"
                  accept="image/*"
                  multiple="multiple"
                  @change="previewMultiImage"
                  class="form-control-file"
                  id="my-file"
                />
              </span>
            </div>
          </li>
          <li class="col-md-6 mb-3">
            <div class="iq-bg-primary rounded px-2 py-3 pointer mr-3 d-flex">
              <select name="tips" id="tips" v-model="post.tip" :disabled='post.albumName ?true : false'>
                <option value="">Select Tips</option>
                <option value="General"> General</option>
                <option value="Health">Health </option>
                <option value="Travel"> Travel </option>
                <option value="Cooking"> Cooking</option>
                <option value="Movies"> Movies and Shows</option>
                <option value="Books"> Books </option>
              </select>
              <!-- <span class="btn btn-primary btn-file px-3 ml-2"
                ><i class="ri-upload-2-line"></i>
                <input
                  type="file"
                  accept="image/*"
                  multiple="multiple"
                  @change="previewMultiImage"
                  class="form-control-file"
                  id="my-file"
                />
              </span> -->
            </div>
          </li>
          <!-- <li class="col-md-6 mb-3">
            <div class="iq-bg-primary rounded p-2 pointer mr-3 d-flex">
              <div class="py-1">
                <div class="py-1">
                  <img
                    src="../../../../assets/images/small/09.png"
                    alt="icon"
                    class="img-fluid"
                  />
                  <span class="btn-file px-2">
                    Feeling/Activity
                    <input type="file" class="form-control-file"
                  /></span>
                </div>
              </div>
            </div>
          </li> -->

          <li class="col-md-6 mb-3" v-for="(item, index) in tab" :key="index">
            <div class="iq-bg-primary rounded p-2 pointer mr-3">
              <div class="py-1">
                <img :src="item.icon" alt="icon" class="img-fluid" />
                <span class="btn-file px-2">
                  {{ item.name }}
                  <input
                    type="file"
                    accept="image/*"
                    multiple="multiple"
                    @change="previewMultiImage"
                    class="form-control-file"
                    id="my-file"
                  />
                </span>
              </div>
            </div>
          </li>
          <li class="col-md-6 mb-3" >
            <div class="iq-bg-primary rounded p-2 pointer mr-3">
              <div class="py-1">
                <img src="../../../../assets/images/small/upload-vid.png" alt="icon" class="img-fluid" />
                <span class="btn-file px-2">
                  Upload Video
                  <input
                    type="file"
                    multiple="multiple"
                    class="form-control-file"
                    id="my-file"
                    @change="previewVideo"
                    accept="video/*"/>
                
                </span>
              </div>
            </div>
          </li>
          <li class="col-md-6 mb-3" >
            <div class="iq-bg-primary rounded p-2 pointer mr-3">
              <div class="py-1">
                <img src="../../../../assets/images/small/upload.png" alt="icon" class="img-fluid" />
                <span class="btn-file px-2">
                  Upload File
                  <input
                    type="file"
                    multiple="multiple"
                    class="form-control-file"
                    id="my-file"
                    @change="previewMultiImage"
                    accept="application/pdf,application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                
                </span>
              </div>
            </div>
          </li>
          <li class="col-md-6 mb-3" @click="postMusic()">
            <div class="iq-bg-primary rounded p-2 pointer mr-3">
              <div class="py-1">
                <img src="../../../../assets/images/small/music.png" alt="icon" class="img-fluid" />
                <span class="btn-file px-2">
                  Post Music
                  <!-- <input
                    type="file"
                    accept="image/*"
                    multiple="multiple"
                    @change="previewMultiImage"
                    class="form-control-file"
                    id="my-file"
                  /> -->
                </span>
              </div>
            </div>
          </li>
         
        </ul>
        </div>
        </div>
        <button
          class="btn btn-primary d-block w-100 mt-3"
          @click="addNewPost(post)"
        >
          Post
        </button>
      </b-modal>
    </iq-card>
  </div>
</template>
<script>
import Post from "../../../../Model/Post";
export default {
  name: "AddSocialPost",
  mounted(){
    var userData =  JSON.parse(localStorage.getItem('user'));
    this.avatar = userData.avatar;
  },
  data() {
    return {
      music:false,
      post: {
        caption: "",
        media: [],
        albumName:'',
        tip:'',
        musicLink:''
      },
      tab: [
        {
          icon: require("../../../../assets/images/small/07.png"),
          name: "Upload Photos",
        },
        {
          icon: require("../../../../assets/images/small/create-album.png"),
          name: "Create Album",
        },
        
        // {
        //   icon: require("../../../../assets/images/small/upload.png"),
        //   name: "Upload File",
        // },
        // {
        //   icon: require("../../../../assets/images/small/upload-vid.png"),
        //   name: "Upload Video",
        // },
        // {
        //   icon: require("../../../../assets/images/small/music.png"),
        //   name: "Upload Music",
        // },
      ],
      preview: null,
      image: null,
      preview_list: [],
      avatar:'',
      // image_list: [],
    };
  },
  methods: {
    postMusic(){
      this.music = true
    },
    addNewPost(post) {
      const post_data = new FormData();
      // post_data.append("media", post.media);
      for( var i = 0; i < this.post.media.length; i++ ){
        let media = this.post.media[i];
        post_data.append('media[' + i + ']', media);
      }
      post_data.append("albumName", post.albumName);
      post_data.append("caption", post.caption);
      post_data.append("tip", post.tip);
      post_data.append("musicLink", post.musicLink);
      this.$emit("addPost", post_data);
      this.post = {
        caption: "",
        media: [],
        albumName:'',
        tip:'',
      };
      this.preview_list = [],
      this.$bvModal.hide("modal1");
    },
    resetPost() {
      this.post = {
        caption: "",
        images: [],
      };
    },
    resetPost() {
      this.post = new Post();
    },

    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.post.media.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
    previewVideo: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.post.media.push(input.files[index]);
          this.post.albumName = "Health";
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.post.media = [];
      this.preview_list = [];
    },
  },
};
</script>
